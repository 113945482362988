import "./WinCoupon.css";
import Form from "./form";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import messi from "./../assets/messi.json";
import zidane from "./../assets/zidane.json";
import { localStorageAvailable } from "./utilities";

interface WinCouponProps {
  correctAnswer: boolean;
  currVideo: any;
  setCurrVideo: any;
  winningRef: any;
  lastVideo: boolean;
}

const WinCoupon: React.FC<WinCouponProps> = ({
  correctAnswer,
  currVideo,
  setCurrVideo,
  winningRef,
  lastVideo,
}) => {
  const [reaction, setReaction] = useState(
    "https://res.cloudinary.com/shulgirit/image/upload/q_auto/v1695887807/wiply/vidquiz/mchaifa/1.png"
  );
  const [lostReaction, setLostReaction] = useState(
    "https://res.cloudinary.com/shulgirit/image/upload/q_auto/v1695887807/wiply/vidquiz/mchaifa/5.png"
  );
  let pointsStorage;
  // if (localStorageAvailable()) {
  //   pointsStorage = localStorage.getItem("points");
  // }
  let title = correctAnswer ? "כל הכבוד" : "זה היה";
  let subTitle = correctAnswer ? "עבודה טובה" : "קרוב";

  useEffect(() => {
    setReaction(
      `https://res.cloudinary.com/shulgirit/image/upload/v1695887807/wiply/vidquiz/mchaifa/${JSON.stringify(
        Math.floor(Math.random() * 5)
      )}.png`
    );
  }, []);

  return (
    <div
      className="win-screen"
      style={{ flexDirection: "column", direction: "ltr" }}
    >
      {!lastVideo && (
        <div className="win-overlay">
          <div className="win-layout">
            <div
              style={{
                zIndex: "2",
                width: "250px",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              {!lastVideo && (
                <>
                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {title}
                  </p>
                  <h1
                    style={{
                      textTransform: "uppercase",
                      fontSize: "30px",
                      marginTop: "-22px",
                      width: "250px",
                    }}
                  >
                    {subTitle}
                  </h1>
                </>
              )}
              {lastVideo && (
                <>
                  {" "}
                
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    השאירו פרטים וקבלו הטבות וסרטונים נוספים
                  </p>
                </>
              )}
            </div>
            {lastVideo && (
              <div className="win-content">
                <Form />
              </div>
            )}
            {!lastVideo && (
              <button
                className="nextButton"
                style={{
                  backgroundImage:
                    "linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32))",
                  backgroundSize: "10px 10px, 10px 10px, 10px 5px",
                  backgroundPosition: "0px 0px, 5px 5px, 0px 0px",
                  color: "white",
                  boxShadow: "0 9px 16px rgb(0 0 0 / 0.6)",
                  padding: "10px 20px",
                  textShadow: "0px 2px 2px rgba(0, 0, 0, 0.9)",
                  fontSize: "25px",
                }}
                onClick={() => {
                  if (currVideo === 0) {
                    window.location.reload();
                  } else {
                    winningRef.current.pause();
                    setCurrVideo((prev: any) => prev - 1);
                  }
                }}
              >
                הסרטון הבא
              </button>
            )}
            {correctAnswer && (
              <Lottie
                style={{ width: "50%" }}
                animationData={messi}
                loop={true}
              />
            )}
            {!correctAnswer && (
              <Lottie
                style={{ width: "50%" }}
                animationData={zidane}
                loop={true}
              />
            )}
          </div>
        </div>
      )}
      {lastVideo && (
        <div className="win-overlay">
          <div className="win-layout2">
            <div
              style={{
                zIndex: "2",
                width: "250px",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              {!lastVideo && (
                <>
                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {title}
                  </p>
                  <h1
                    style={{
                      textTransform: "uppercase",
                      fontSize: "30px",
                      marginTop: "-22px",
                      width: "250px",
                    }}
                  >
                    {subTitle}
                  </h1>
                </>
              )}
              {lastVideo && (
                <>
                 
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    נהניתם! השאירו פרטים וקבלו סרטונים נוספים
                  </p>
                </>
              )}
            </div>
            {lastVideo && (
              <div className="win-content">
                <Form />
              </div>
            )}
            {!lastVideo && (
              <button
                className="nextButton"
                style={{
                  backgroundColor: "rgb(0, 118, 56)",
                  backgroundSize: "10px 10px, 10px 10px, 10px 5px",
                  backgroundPosition: "0px 0px, 5px 5px, 0px 0px",
                  color: "white",
                  boxShadow: "0 9px 16px rgb(0 0 0 / 0.6)",
                  padding: "10px 20px",
                  textShadow: "0px 2px 2px rgba(0, 0, 0, 0.9)",
                  fontSize: "25px",
                }}
                onClick={() => {
                  if (currVideo === 0) {
                    window.location.reload();
                  } else {
                    winningRef.current.pause();
                    setCurrVideo((prev: any) => prev - 1);
                  }
                }}
              >
                הסרטון הבא
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WinCoupon;
