import React, { useEffect, useState } from "react";

import VideoPlayer from "./components/VideoPlayer";
import "./App.css";

import VideoObject from "./interfaces";
import {
  getIdentityId,
  getCurrentState,
  localStorageAvailable,
} from "./components/utilities";
import Confetti from "react-confetti";

import Form from "./components/form";
import { useWindowSize } from "react-use";
import HamalLogo from "./assets/hamallogowhite.png";

const PoweredBy =
  "https://res.cloudinary.com/shulgirit/image/upload/w_118,f_auto/v1692628450/wiply/vidquiz/Group_42vidquiz_typlrk.png";

const App: React.FC = () => {
  const [videoOrder, setVideoOrder] = useState<VideoObject[]>();
  const [loading, setLoading] = useState(true);
  const [showWinCoupon, setShowWinCoupon] = useState(false);

  useEffect(() => {
    getCurrentState("clearLocalStorage")
      .then((res) => {
        // if (res && localStorageAvailable()) {
        //   localStorage.removeItem("identityId");
        //   localStorage.removeItem("videosSeen");
        //   localStorage.removeItem("points");
        //   localStorage.removeItem("videosToSee");
        //   localStorage.removeItem("lastFetched");
        // }
        // console.log("removed");
        getIdentityId(setLoading, setVideoOrder);
      })
      .catch((error) => console.log(error));

    // @ts-ignore
  }, []);

  const { width, height } = useWindowSize();
  let pointsStorage;
  // if (localStorageAvailable()) {
  //   pointsStorage = localStorage.getItem("points");
  // }

  return loading ? (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
    </div>
  ) : (
    <div className="App">
      <div id="powered-by-vidquiz">
        <img width="50" src={PoweredBy} alt="powered by"></img>
      </div>
      {/* <div id="score">
        <div className="circle">
          {pointsStorage && (
            <>
              {" "}
              <div className="rotating-border"></div>
              <span className="points">
                <span style={{ fontSize: "12px" }}>
                  <img
                    src="https://res.cloudinary.com/shulgirit/image/upload/v1706096476/wiply/vidquiz/365scores/star-white_bq4som.png"
                    width={15}
                    alt="points"
                  ></img>
                </span>
                {pointsStorage}
              </span>
            </>
          )}
        </div>
      </div> */}

      {showWinCoupon && (
        <Confetti
          style={{ zIndex: 10, position: "absolute" }}
          width={width}
          height={height}
          colors={["rgb(0, 0, 0)", "rgb(255, 0, 0)"]}
        />
      )}
      <div className="lineBottom"></div>
      <div className="player-wrapper">
        {videoOrder && videoOrder.length > 0 ? (
          <>
            <div style={{ color: "white", fontSize: "3em", display: "flex" }}>
              <VideoPlayer
                showWinCoupon={showWinCoupon}
                setShowWinCoupon={setShowWinCoupon}
                videoData={videoOrder}
                setVideoData={setVideoOrder}
              />
            </div>
          </>
        ) : (
          <div className="register">
            <div style={{ marginBottom: 75 }}>
              <h1
                className="glass"
                style={{
                  color: "white",
                  zIndex: 5,
                  position: "relative",
                  textAlign: "center",
                }}
              >
                הרשמו לצפייה בסרטונים נוספים
              </h1>
              <Form />
            </div>
          </div>
        )}
      </div>
      <div className="footer-logo">
        <img src={HamalLogo} alt="stari" className="smalllogo" />
      </div>
    </div>
  );
};

export default App;
